<template lang="pug">
.accuracy__filter.filter
	Tooltip(
		trigger="clickToToggle"
		@hide="updateResults"
		@show="showTooltip"
		:isNeedClosePopup="isNeedClosePopup"
	)
		template(slot="reference")
			.filter__label.flex.items-center
				span {{ defaultValue }}
				ChevronDownIcon.filter__label-icon(
					:size="iconSize"
					:class="{'tooltip-active': isActiveTooltip}"	
				)
		template
			.search-wrapper(v-if="hasSearch")
				input.search-input(v-model="searchValue" placeholder="Поиск...")
				span.search-icon
				button.clear-search(v-if="searchValue" @click.stop="clearSearch")
					span.clear-icon
						CloseIcon
			.select__header(v-if="!limit")
				button.btn(@click="change(!isSelectedItem)") {{ isSelectedItem ? 'Clear all' : 'Select all' }}
			CheckTree(
				ref="tree"
				v-if="tree"
				:items="items.values"
				@select-checkbox="updateTree($event)"
			)
			multi-select(
				v-else
				:items="searchItems"
				:limit="limit"
				@select-checkbox="selectCheckbox($event)"
				:hasSelectAll="hasSelectAll"
				:hasSearch="hasSearch"
			)
</template>

<script>
import ConfigInLocalStorage from '@/helpers/LocalStorage/Config'
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'
import Tooltip from '@/components/Elements/Tooltip.vue'
import MultiSelect from '@/components/Elements/MultiSelect/MultiSelect.vue'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
import CheckTree from "../../Elements/СheckTree";
import CloseIcon from '@/assets/svg/close.svg';

export default {
		name: 'CustomFilter',

		components: {
		CheckTree,
				ConfigInLocalStorage,
				FilterInLocalStorage,
				Tooltip,
				MultiSelect,
				ChevronDownIcon,
        CloseIcon,
		},
		props: {
		name: {
				type: String,
				default: '',
		},
		items: {
				type: Object,
				default: () => {},
		},
		iconSize: {
			type: [Number, String],
			default: 16,
		},
		hasSelectAll: {
			type: Boolean,
			default: true,
		},
		hasSearch: {
			type: Boolean,
			default: false,
		},
		tree: {
			type: Boolean,
			default: false,
		},
		storageIsFull: {
			type: Boolean,
			default: false,
		},
		isMainPage: {
			type: Boolean,
			default: false,
		},
		checkedStores: {
			type: Number,
			default: 0,
		},
		},
		data() {
				return {
						limit: null,
						currentLSState: null,
						isClicked: false,
						temporaryValues: null,
						isNeedClosePopup: false,
            isActiveTooltip: false,
            searchValue: '',
				}
		},
		computed: {
      searchItems() {
        if (this.searchValue === '') return this.items.values;
        return this.items.values.filter((item) => item.name.trim().toLowerCase().includes(this.searchValue.trim().toLowerCase()));
      },
		isActive() {
			return this.items.values.length || Object.keys(this.items.values).length
		},
				defaultValue() {
						return this.items.name
				},
		dymanicComponent() {
			return this.isActiveTooltip ? 'ChevronUpIcon' : 'ChevronDownIcon'
		},
		// TODO отрефакторить
		isSelectedItem() {
			if(this.tree) {
				let test = false
				for (const key in this.items.values) {
					this.items.values[key].forEach(el => {
						if(el.checked) {
							test = true
						}
					})
				}
				return test
			} else {
				if (!this.items.values.length) return []

				return this.items.values.map(el => {
					return el.checked
				}).includes(true)
			}
		}
		},
		methods: {
      clearSearch() {
        this.searchValue = '';
      },
				updateTree() {
			this.$emit('update')
				},
		change(value) {
			if(this.tree) {
				for (const key in this.items.values) {
					this.$refs.tree['_data'].parrent[key] = value
					this.items.values[key].forEach(el => {
						this.$set(el, 'checked', value)
					})
				}
			} else {
				this.items.values.forEach(el => {
					this.$set(el, 'checked', value)
				})
			}
			this.$emit('update')
		},
		showTooltip() {
			this.isActiveTooltip = true
			// По ТЗ нет необходимости отслеживать нажатия на фильтр-бренды
			if (this.name === 'Brands') return
			// Аналитика. Событие клика по фильтру (кроме брендов)
			// Google Analitycs GTAG (lowecase names in events)
			// [ ] Подключить аналитику, когда будет ID счетчика
			// window.gtag('event', `filter_click_${this.name.toLowerCase()}`)
			// Yandex metrics
			// [ ] Подключить метрику, когда будет ID счетчика
			// window.ym(90017460,'reachGoal',`filter_click_${this.name.toLowerCase()}`)
		},
		setLimit() {
			this.limit = ConfigInLocalStorage.getLimits(this.items.name) || null
		},
		clearLimit() {
			this.limit = null
		},
		selectCheckbox(id) {
			this.isClicked = true
			const item = this.items.values.find((el) => el.id === id);
			const index = this.items.values.indexOf(item);
			if (item && index !== -1) {
				this.$set(this.items.values, index, {...item, checked: !this.items.values[index].checked})
			}
			this.$emit('update')
		},
		updateResults() {
			this.isActiveTooltip = false
			if (!this.isClicked) return
			this.$emit('update-results')
			this.isClicked = false
		},
		hidePopup() {
			this.isNeedClosePopup = !this.isNeedClosePopup
		},
		checkItems(amount) {
			for (let i = 0; i < amount; i++) {
				this.$set(this.items.values, i, { ...this.items.values[i], checked: true })
			}
		},
		// uncheckedItems() {
		// 	let uncheckedAmount = 0
		// 	for (let i = this.items.values.length-1; uncheckedAmount < (this.checkedStores - this.limit); i--) {
		// 		if (
		// 			Object.prototype.hasOwnProperty.call(this.items.values[i], 'checked') &&
		// 			this.items.values[i].checked
		// 		) {
		// 			this.$set(this.items.values, i, { ...this.items.values[i], checked: false })
		// 			uncheckedAmount = uncheckedAmount + 1;
		// 		}
		// 	}
		// },
		},
	// watch: {
	// 	'isMainPage': {
	// 		immediate: true,
	// 		handler(value) {
	// 			if (this.name !== 'Store') return
	//
	// 			if (value) {
	// 				this.clearLimit()
	// 				if (!this.storageIsFull) {
	// 					this.checkItems(this.items.values.length)
	// 				}
	// 			} else {
	// 				this.setLimit()
	// 				if (this.checkedStores > this.limit) {
	// 					this.uncheckedItems()
	// 				}
	// 			}
	// 		}
	// 	},
	//
	// },
}
</script>

<style lang="scss" scoped>
.btn {
	cursor: pointer;
	color: #000;
	&:focus {
		outline: none;
	}
}
::v-deep.popper {
	padding: 0;
}
.select__header {
	height: 48px;
	position: relative;
	padding: 16px;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: color(primary);

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		border-bottom: 1px color(gray-600) solid;
	}
}
.filter {
	&__list {
		max-height: 300px;
		overflow: auto;
	}

	&__label {
		margin-left: 10px;
		padding: 0 4px 0 10px;
		color: color(white);
		cursor: pointer;
		user-select: none;
		&-icon {
			margin-left: 4px;
			transition: .2s all ease
		}
	}

	&__bottom {
		margin-top: 15px;
		padding-top: 5px;
		border-top: 1px solid color(gray-300);
	}
	&__clear {
		color: color(blue-bright);
		cursor: pointer;
	}
	&__apply {
		padding: 4px 10px 5px 10px;
		background-color: color(blue-bright);
		color: color(white);
		cursor: pointer;
		border-radius: 4px;
	}
}
select{
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus{
		outline: none;
	}
}
.apply {
	margin-top: 12px;
		border: 1px solid black;
		padding: 5px;
		cursor: pointer;
		&:disabled {
				cursor: not-allowed;
				color: gray;
				background-color: lightgray;
				border-color: lightgray;
		}
}
.tooltip-active {
	transform: rotate(180deg);
}

.search-wrapper {
  position: relative;
  padding: 10px 12px;
  width: 100%;
  height: 48px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    //left: 12px;
    //right: 12px;
    height: 1px;
    background-color: #E7E7E7;
  }
}

.search-input {
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  height: 100%;
  font-family: 'OpenSans';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #000000;
  outline: none;

  &::placeholder {
    font-family: 'OpenSans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #A3AEBE;
  }
}

.search-icon {
  position: absolute;
  display: block;
  left: 20px;
  top: 16px;
  width: 16px;
  height: 16px;
  background-image: url('~@/assets/img/search.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.clear-search {
  position: absolute;
  padding: 4px;
  right: 12px;
  top: 18px;
  width: 18px;
  height: 18px;
  outline: none;

  &:focus {
    outline: none;
  }
}

.clear-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: #9D9DB0;
    }
  }
}
</style>
