<template lang="pug">
	.tree
		.list(
			v-for="(item, parentName) in items"
			:key="parentName"
		)
			.parrent(v-if="currentParrents.hasOwnProperty(parentName)")
				input(
					type="checkbox"
					:id="`parent_${parentName}`"
					:class="parrentClass(parentName)"
					:checked="Boolean(currentParrents[parentName])"
					@change="use($event, parentName)"
				)
				label(
					:for="`parent_${parentName}`"
					:class="childClass(parentName)"
				) {{ parentName }}
				i.symbol(@click="toggleChildren(parentName)") {{ changeSymbol(parentName) }}

			template(v-if="currentParrents.hasOwnProperty(parentName)")
				.child(
					v-for="i in item"
					:key="i.id"
				)
					.child-item(v-show="checkEnabled(parentName)")
						input(
							type="checkbox"
							class="custom-checkbox"
							:id="i.id + i.parentId"
							:parrent-name="parentName"
							v-model="i.checked"
							@change="change($event, parentName)"
						)
						label.mt-2(
							:for="i.id + i.parentId"
							:class="childClass(i)"
						) {{ i.name }}
</template>

<script>
export default {
	name: 'checkTree',
	props: {
		items: {
			type: [Object, Array],
			required: true,
		},
	},
	data() {
		return {
			parrent: {},
			child: {},
			enabledChildren: {},
		}
	},
	computed: {
		currentParrents() {
			if (!this.items || !Object.keys(this.items).length) return {};

			const parentClasses = {};
			const itemsEntries = Object.entries(this.items);

			itemsEntries.forEach(([key, value]) => {
				const checkedValuesLength = value.filter((item) => item.checked).length;

				parentClasses[key] = checkedValuesLength === value.length
						? true
						: checkedValuesLength === 0
								? false
								: 'not-complete'
			})

			return parentClasses;
		},
	},
	methods: {
		checkEnabled(parentName) {
			return Object.prototype.hasOwnProperty.call(this.enabledChildren, parentName) && this.enabledChildren[parentName]
		},

		isChecked(value){
			return value.checked ? value.checked : false
		},

		parrentClass(key) {
			return {
				'custom-checkbox-parrent' : this.currentParrents[key] == 'not-complete',
				'custom-checkbox' :  this.currentParrents[key] != 'not-complete',
			}
		},

		childClass(key) {
			return {
				'checked': this.isChecked(key) || this.currentParrents[key]
			}
		},

		use(e, key) {
			this.items[key].forEach(el => {
				this.$set(el, 'checked', e.target.checked)
			})
			this.$emit('select-checkbox', key)
		},

		change(event, key) {
			this.$emit('select-checkbox', key)

			const name = event.target.getAttribute('parrent-name')
			const count = this.items[name].reduce((acc, el) => {
				if(el.checked === true) {
					return acc += 1
				} else {
					return acc
				}
			}, 0)

			if(count) {
				this.parrent[name] = count === this.items[name].length ? true : 'not-complete'
			} else {
				this.parrent[name] = count === 0 ? false : 'not-complete'
			}
		},

		toggleChildren(id) {
			if (Object.prototype.hasOwnProperty.call(this.enabledChildren, id)) {
				this.enabledChildren[id] = !this.enabledChildren[id]
			} else {
				this.$set(this.enabledChildren, id, true)
			}
		},

		changeSymbol(id) {
			return this.enabledChildren[id] ? '-' : '+'
		},

	},

	watch: {
		'items': {
			deep: true,
			handler(value) {
				Object.entries(this.items).forEach(([key, values]) => {
					const amountChecked = values.reduce((acc, current) => {
						if(current.checked) {
							acc+= current.checked
						}
						return acc
					}, 0)
					this.parrent[key] = amountChecked === values.length ? true : amountChecked === 0 ? false : 'not-complete'
				})

				if(!Object.keys(value).length) {
					this.parrent = {}
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.tree {
	max-height: 300px;
	overflow-y: scroll;
}

.checked {
	background-color: rgba(87, 68, 214, 0.05);
	&::before {
		border: 2px solid color(violet) !important;
	}
}

.child {
	display: flex;
	padding-left: 24px;
	&-item {
		width: 100%;
	}
}

.parrent {
	display: flex;
	flex-direction: column;
	position: relative;
}

.symbol {
	font-style: normal;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	font-size: 22px;
	color: gray;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 100%;
	transition: .3s all ease;
	&:hover {
		color: #5744D6;
	}
}

.custom-checkbox, .custom-checkbox-parrent {
	display: none;
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.custom-checkbox+label, .custom-checkbox-parrent+label {
	display: flex;
	user-select: none;
	font-size: 14px;
	line-height: 20px;
	position: relative;
	width: 100%;
	padding-left: 12px;
	padding-top: 12px;
	padding-right: 32px;
	padding-bottom: 12px;
	display: flex;
	align-items: start;
	&:hover {
		background-color: rgba(87, 68, 214, 0.05);
		&::before {
			border: 2px solid color(violet) !important;
		}
	}
}
.custom-checkbox+label::before, .custom-checkbox-parrent+label::before {
	content: '';
	display: inline-block;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	flex-grow: 0;
	border: 2px solid #BDBDBD;
	border-radius: 4px;
	color: #212121;
	background-repeat: no-repeat;
	background-size: 50% 50%;
	margin: 0 8px 0 0;
}
.custom-checkbox:checked+label::before, .custom-checkbox-parrent:checked+label::before {
	content: "";
	display: inline-block;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	flex-grow: 0;
	font-size: 12px;
	border: 2px solid #BDBDBD;
	border-radius: 4px;
	color: #212121;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
	position: relative;
}
.custom-checkbox:checked+label::after {
	font-family: "Material Icons";
	content: "check";
	position: absolute;
	left: 15px;
	top: 15px;
	font-size: 18px;
	color: #5744D6 !important;
}

.custom-checkbox-parrent:checked+label::after {
	content: "-";
	position: absolute;
	left: 19px;
	top: 10px;
	font-size: 34px;
	color: #212121;
}

</style>
